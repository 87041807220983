import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Form, notification, Input } from 'antd';
import ImageUpload from './ImageUpload';
import axios from 'axios';
import { useStore } from '../helpers/zustand';
import { COOKIES_TOKEN_NAME, RouteConstants } from '../helpers/constants';
import { ACCOUNTS_MS_API_URL } from '../helpers/constants';
import Loader from './Loader';
import Cookies from 'js-cookie';
import close_icon from '../img/close.svg';
import moment from 'moment';
import { accounts_ms } from '../helpers/api';

const EditVerificationModal = ({
  isOpen,
  handleClose,
  refetch,
  verificationDetails,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  verificationDetails: any;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submittingImages, setSubmittingImages] = useState(false);
  const token = Cookies.get(`${COOKIES_TOKEN_NAME}`);

  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');

  const [frontFile, setFrontFile] = useState<any>();
  const [backFile, setBackFile] = useState<any>();

  const [user_name] = useStore((state: any) => [state.user_name]);

  const [details, setDetails] = useState<any>(verificationDetails);

  const handleImageUpload = (type: string, url: string, file: any) => {
    if (type === 'front') {
      setFrontImage(url);
      setFrontFile(file);
    } else if (type === 'back') {
      setBackImage(url);
      setBackFile(file);
    }
  };

  const signImage = async () => {
    setSubmittingImages(true);
    try {
      //front
      const { data } = await accounts_ms.post(
        `${RouteConstants.IDENTITY_SIGNED_URL}`,
        {
          front_mime: frontFile?.type,
          back_mime: backFile?.type,
        }
      );

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: data?.payload?.front?.upload_url,
        headers: {
          'Content-Type': frontFile?.type,
        },
        data: frontFile,
      };

      await axios.request(config);

      //back

      let back_config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: data?.payload?.back?.upload_url,
        headers: {
          'Content-Type': backFile?.type,
        },
        data: backFile,
      };

      await axios.request(back_config);

      setSubmittingImages(false);
    } catch (e: any) {
      //handleClose();
      notification.error(e?.response?.data?.message || e?.message);
      setSubmittingImages(false);
    }
    // return new Promise((resolve, reject) => {
    //   // 2. Upload image mime type
    //   return axios
    //       .post(
    //         `${ACCOUNTS_MS_API_URL}/v1/identification/image/presigned-upload`,
    //         {
    //           front_mime: cardImg?.file?.type,
    //           back_mime: cardImg?.file?.type,
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         uploadFileToPresignedUrl(
    //           'front',
    //           response.data?.data,
    //           cardImg?.file
    //         )
    //           .then(() => {
    //             // Proceed to save client_url to state
    //             resolve(response.data?.data);
    //           })
    //           .catch((error) => {
    //             reject(error);
    //           });
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   if (cardImg?.type === 'front') {

    //   } else if (cardImg?.type === 'back') {
    //     return axios
    //       .post(
    //         `${ACCOUNTS_MS_API_URL}/v1/identification/image/presigned-upload`,
    //         {
    //           mime: cardImg?.file?.type,
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         uploadFileToPresignedUrl('back', response.data?.data, cardImg?.file)
    //           .then(() => {
    //             // Proceed to save client_url to state
    //             resolve(response.data?.data);
    //           })
    //           .catch((error) => {
    //             reject(error);
    //           });
    //       })
    //       .catch((error) => {
    //         reject(error);
    //       });
    //   }
    // });
  };

  const handleSubmit = async () => {
    const values = form.getFieldsValue();
    //console.log(values);
    setLoading(true);

    let verificationDetails = {
      name: user_name,
      country_code: 'GH',
      serial: values?.serial,
      issued_date: values?.issued_date,
      expiry_date: values?.expiry_date,
    };
    //console.log(verificationDetails);
    try {
      await axios.post(
        `${ACCOUNTS_MS_API_URL}/v1/identification`,
        verificationDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      setSubmittingImages(true);
      await signImage();

      refetch();
      handleClose();
    } catch (e: any) {
      notification.error({ message: e?.response.data?.message || e?.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setDetails(verificationDetails);
      form.setFieldValue('serial', verificationDetails?.serial);
      if (verificationDetails) {
        form.setFieldValue(
          'issued_date',
          moment(verificationDetails?.issued_at).format('yyyy-MM-DD')
        );

        form.setFieldValue(
          'expiry_date',
          moment(verificationDetails?.expires_at).format('yyyy-MM-DD')
        );
      }

      if (verificationDetails?.media?.front_url) {
        setFrontImage(verificationDetails?.media?.front_url);
      }

      if (verificationDetails?.media?.back_url) {
        setBackImage(verificationDetails?.media?.back_url);
      }
    }

    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-50 '
        onClose={() => {
          handleClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto '>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full  max-w-3xl  transform overflow-hidden bg-white rounded-2xl shadow-xl transition-all'>
                <div className='bg-[#F5F5F5] p-5 grid grid-cols-3 items-center'>
                  <div
                    className='flex flex-row items-center gap-2 text-left'
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <img src={close_icon} alt='Close' />
                    <p>Close</p>
                  </div>
                  <div className='mx-auto'>Upload National ID</div>
                  <div></div>
                </div>
                <section className='p-5'>
                  <Form
                    form={form}
                    layout='vertical'
                    className='w-full'
                    data-testid='edit-verification-submit-form'
                  >
                    <div className='w-full flex items-center flex-wrap'>
                      <Form.Item
                        label={
                          <label className='block capitalize text-sm font-semibold'>
                            National ID Number
                          </label>
                        }
                        className='w-full mb-2 pr-3'
                        name='serial'
                        colon={false}
                      >
                        <Input
                          data-testid='edit-verification-country-id-input'
                          type='text'
                          placeholder='GHA-67348994-0'
                          autoComplete='off'
                          value={details?.serial || verificationDetails?.serial}
                          disabled={loading}
                          size='large'
                          onChange={(e) =>
                            setDetails((prevData: any) => ({
                              ...prevData,
                              serial: e.target.value,
                            }))
                          }
                          className='w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                        />
                      </Form.Item>
                    </div>
                    <div className='w-full flex items-center flex-wrap'>
                      <Form.Item
                        colon={false}
                        label='Issued Date'
                        name='issued_date'
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                        className='w-1/2 mb-2 pr-3'
                      >
                        <Input
                          type='date'
                          value={details?.issued_at}
                          onChange={(e) =>
                            setDetails((prevData: any) => ({
                              ...prevData,
                              issued_at: e.target.value,
                            }))
                          }
                          className='h-12'
                          autoComplete='off'
                          size='large'
                        />
                      </Form.Item>
                      <Form.Item
                        colon={false}
                        label='Expiry Date'
                        name='expiry_date'
                        rules={[
                          { required: true, message: 'This field is required' },
                        ]}
                        className='w-1/2 mb-2 pr-3'
                      >
                        <Input
                          type='date'
                          value={details?.expires_at}
                          onChange={(e) =>
                            setDetails((prevData: any) => ({
                              ...prevData,
                              expires_at: e.target.value,
                            }))
                          }
                          className='h-12'
                          autoComplete='off'
                          size='large'
                        />
                      </Form.Item>
                    </div>

                    <div className='mt-6 w-full flex items-center flex-wrap'>
                      <Form.Item
                        name='front_image_url'
                        label={
                          <label className='block text-sm font-semibold'>
                            ID card front image
                          </label>
                        }
                        className='w-1/2 mb-2 pr-3'
                        colon={false}
                      >
                        <ImageUpload
                          placeholder={
                            frontImage ? frontImage : details?.front_image_url
                          }
                          uploadImage={(url, file) => {
                            handleImageUpload('front', url, file);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name='back_image_url'
                        label={
                          <label className='block text-sm font-semibold'>
                            ID card back image
                          </label>
                        }
                        className='w-1/2 mb-2 pr-3'
                        colon={false}
                      >
                        <ImageUpload
                          placeholder={
                            backImage ? backImage : details?.back_image_url
                          }
                          uploadImage={(url, file) => {
                            // handleSignImage("back", url, file);
                            handleImageUpload('back', url, file);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className='w-full flex justify-end items-end mt-6 mb-10'>
                      <div className='mr-2'>
                        <button
                          onClick={() => handleClose()}
                          className='rounded-md border font-bold capitalize px-7 py-3 border-red-101 text-red-101'
                        >
                          cancel
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={loading || submittingImages}
                          type='button'
                          onClick={() => {
                            handleSubmit();
                          }}
                          className='flex rounded-md bg-red-101 text-white capitalize  px-7 py-3'
                        >
                          {loading || (submittingImages && <Loader />)}
                          upload
                        </button>
                      </div>
                    </div>
                  </Form>
                </section>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditVerificationModal;
