import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/reset.css';
import 'react-phone-number-input/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from './routes/router';

const queryClient = new QueryClient();

const AppWrapper = () => <App />;

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default AppWrapper;
