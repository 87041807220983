import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import { Dropdown, Skeleton } from 'antd';
import { ACCOUNTS_MS_API_URL, COOKIES_TOKEN_NAME } from '../helpers/constants';
import cookies from 'js-cookie';
import axios from 'axios';
import { COOKIES_USR } from '../helpers/constants';
import { useStore } from '../helpers/zustand';
import LogoutModal from './LogoutModal';
import ContactUsModal from './ContactUsModal';
import { HiMenuAlt2 } from 'react-icons/hi';
import { NavLink, useLocation } from 'react-router-dom';
import profile_active from '../img/profile_active.png';
import profile_inactive from '../img/profile_inactive.png';
import lock_active from '../img/lock_active.png';
import lock_inactive from '../img/lock_inactive.png';
import verify_active from '../img/verify_active.png';
import verify_inactive from '../img/verify_inactive.png';
import { Drawer } from 'antd';

const DrawerComp = ({
  visible,
  closeDrawer,
  loading,
  userId,
  setVisible,
  details,
  items,
}: {
  visible: boolean;
  closeDrawer: () => void;
  loading: any;
  userId: any;
  items: any;
  setVisible: any;
  details: any;
}) => {
  let activeStyle = {
    color: '#B40303',
    backgroundColor: '#FFCECC',
    borderRadius: 12,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 10,
    paddingBottom: 10,
    margin: 'auto',
  };

  const location = useLocation();
  const isHome = location.pathname === '/' || location.pathname === '/account';

  return (
    <>
      <div className='block md:hidden overflow-auto pt-14'>
        <Drawer
          placement='left'
          closable={false}
          onClose={closeDrawer}
          open={visible}
          width={300}
          mask={false}
        >
          {userId && (
            <div className='flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl'>
              <Dropdown
                trigger={['click']}
                menu={{ items }}
                overlayStyle={{
                  boxShadow: '#1d2a9f 0px 1px 4px',
                  position: 'relative',
                  width: 256,
                  borderRadius: 8,
                }}
                onOpenChange={() => setVisible(!visible)}
                placement='bottomRight'
                arrow
              >
                {loading ? (
                  <div className='flex items-center user py-2'>
                    <div className='h-12 w-12'>
                      <Skeleton avatar />
                    </div>

                    <div className='text-left pl-2 pr-4 leading-snug hidden md:block'>
                      <div className='text-oya-ghana-body-sb font-normal'>
                        <div className='appear animate-pulse h-4 bg-black bg-opacity-[5%] rounded text-transparent w-fit'>
                          Firstname Middlename Lastname
                        </div>
                        <div className='appear animate-pulse h-4 mt-2 bg-black bg-opacity-[5%] rounded text-transparent w-fit'>
                          +2330000000000
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    type='button'
                    className='flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl'
                  >
                    <div className='flex items-center user'>
                      {details?.image ? (
                        <img
                          src={details?.image}
                          className='appear rounded-full object-cover w-12 h-12 md:w-14 md:h-14'
                          alt='User'
                        />
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                          />
                        </svg>
                      )}

                      <div className='text-left pl-2 leading-snug block'>
                        <h2 className='text-oya-ghana-body-normal font-medium'>
                          {details?.name}
                        </h2>
                        <div className='text-oya-ghana-body-sb font-normal'>
                          {details?.phone}
                        </div>
                      </div>

                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='10'
                        viewBox='0 0 18 10'
                        stroke='currentColor'
                        className='w-4 h-4 text-oya-ghana-green md:mb-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z'
                        />
                      </svg>
                    </div>
                  </button>
                )}
              </Dropdown>
            </div>
          )}
          <ul className='mt-10'>
            <li>
              {isHome ? (
                <NavLink
                  to='/account'
                  className='flex items-center text-md text-red-101 bg-[#FFCECC] font-semibold rounded-xl px-[25px] py-[10px] m-auto '
                >
                  <img
                    src={profile_active}
                    alt='account profile'
                    className='pr-2'
                  />
                  Profile
                </NavLink>
              ) : (
                <NavLink
                  to='/account'
                  className='flex items-center text-md font-semibold text-oya-ghana-faded-gray px-[25px] py-[10px] '
                >
                  {' '}
                  <img
                    src={profile_inactive}
                    alt='account profile'
                    className='pr-2'
                  />
                  Profile
                </NavLink>
              )}
            </li>
            <li>
              <NavLink
                to='/security-details'
                className='flex items-center text-md font-semibold text-oya-ghana-faded-gray px-[25px] py-[10px]'
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                {({ isActive }) => (
                  <>
                    <img
                      src={isActive ? lock_active : lock_inactive}
                      alt='account profile'
                      className='pr-2'
                    />
                    Security
                  </>
                )}
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/verification'
                className='flex items-center text-md font-semibold text-oya-ghana-faded-gray px-[25px] py-[10px]'
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                {({ isActive }) => (
                  <>
                    <img
                      src={isActive ? verify_active : verify_inactive}
                      alt='account profile'
                      className='pr-2'
                    />
                    Verification
                  </>
                )}
              </NavLink>
            </li>
          </ul>
        </Drawer>
      </div>
    </>
  );
};

const Navbar = ({ title, button }: { title: string; button?: any }) => {
  //const authenticated = useAuthentication();
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [logoutModal, openLogoutModal] = useState(false);
  const [setUserName] = useStore((state) => [state.setUserName]);
  const [contactUs, openContactUs] = useState(false);
  const [visible, setVisible] = useState(false);
  const [drawer_visible, setDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    name: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    ice1_phone: '',
    ice2_phone: '',
    phone: '',
    birthdate: '',
    gender: '',
    image: '',
    country: {
      code: '',
      name: '',
      phone_code: '',
    },
  });

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <button className='pl-[13px] text-oya-ghana-header-h7 font-medium text-black'>
          Contact us
        </button>
      ),
      icon: (
        <img
          src={require('../img/sms.png')}
          className=' w-5 h-5'
          alt='sms icon'
        />
      ),
      onClick: () => {
        openContactUs(true);
      },
    },

    {
      icon: (
        <img
          src={require('../img/logout.png')}
          className=' w-5 h-5'
          alt='logout icon'
        />
      ),
      onClick: () => {
        // handleLogout();
        openLogoutModal(true);
        // dispatch(logout());
      },
      label: (
        <button className='pl-4 text-oya-ghana-header-h7 font-medium text-black'>
          Logout
        </button>
      ),
      key: '1',
    },
  ];

  const getDetails = async () => {
    if (userId) {
      setLoading(true);
      try {
        const user = await axios.get(`${ACCOUNTS_MS_API_URL}/v1/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        //console.log(user.data.data);
        setDetails(user.data?.payload);
        setUserName(user.data?.payload?.name);
        setLoading(false);
        //return user.data?.payload;
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  };

  const onClose = () => {
    setDrawerVisible(false);
  };
  const showDrawer = () => {
    setDrawerVisible(true);
  };

  useEffect(() => {
    getDetails();
    const handleResize = () => {
      const isMd = window.matchMedia('(min-width: 768px)').matches;
      if (isMd) {
        setDrawerVisible(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className='bg-white md:border-b-[1px] border-[#D4D3D3] w-full md:w-[79%] lg:w-[89.5%] fixed header z-20 h-24 top-0 right-0 flex '>
      <LogoutModal
        isOpen={logoutModal}
        handleClose={() => {
          openLogoutModal(false);
        }}
      />
      <ContactUsModal
        isOpen={contactUs}
        handleClose={() => {
          openContactUs(false);
        }}
      />

      <DrawerComp
        visible={drawer_visible}
        closeDrawer={() => onClose()}
        loading={loading}
        userId={userId}
        setVisible={setVisible}
        details={details}
        items={items}
      />

      <div className='flex justify-between items-center pt-3 w-full px-[2%]'>
        <div className='flex flex-row items-center gap-4'>
          <HiMenuAlt2
            className='w-[24px] h-[24px] block md:hidden'
            color='#B40303'
            onClick={() => {
              showDrawer();
              setVisible(true);
            }}
          />

          <p className='leading-[36px] text-2xl font-bold text-[#484442]'>
            {title}
          </p>
        </div>

        <div className='flex flex-row items-center gap-6'>
          <div className='cursor-pointer'>{button}</div>
          {userId && (
            <div className=' hidden md:flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl'>
              <Dropdown
                trigger={['click']}
                menu={{ items }}
                overlayStyle={{
                  boxShadow: '#1d2a9f 0px 1px 4px',
                  position: 'relative',
                  width: 256,
                  borderRadius: 8,
                }}
                onOpenChange={() => setVisible(!visible)}
                placement='bottomRight'
                arrow
              >
                {loading ? (
                  <div className='flex items-center user py-2'>
                    <div className='h-12 w-12'>
                      <Skeleton avatar />
                    </div>

                    <div className='text-left pl-2 pr-4 leading-snug hidden md:block'>
                      <div className='text-oya-ghana-body-sb font-normal'>
                        <div className='appear animate-pulse h-4 bg-black bg-opacity-[5%] rounded text-transparent w-fit'>
                          Firstname Middlename Lastname
                        </div>
                        <div className='appear animate-pulse h-4 mt-2 bg-black bg-opacity-[5%] rounded text-transparent w-fit'>
                          +2330000000000
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    type='button'
                    className='flex justify-center items-center profile py-1 px-2 bg-hoverDashBg rounded-xl'
                  >
                    <div className='flex items-center user'>
                      {details?.image ? (
                        <img
                          src={details?.image}
                          className='appear rounded-full object-cover w-12 h-12 md:w-14 md:h-14'
                          alt='User'
                        />
                      ) : (
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='w-12 h-12 md:w-14 md:h-14 text-oya-ghana-green'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                          />
                        </svg>
                      )}

                      <div className='text-left pl-2 pr-4 leading-snug hidden md:block'>
                        <h2 className='text-oya-ghana-body-normal font-medium'>
                          {details?.name}
                        </h2>
                        <div className='text-oya-ghana-body-sb font-normal'>
                          {details?.phone}
                        </div>
                      </div>

                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='10'
                        viewBox='0 0 18 10'
                        stroke='currentColor'
                        className='w-4 h-4 text-oya-ghana-green md:mb-4'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M9 6.18373L16.0003 0.661255L18 2.23878L9 9.33879L0 2.23878L1.99969 0.661255L9 6.18373Z'
                        />
                      </svg>
                    </div>
                  </button>
                )}
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
