import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import cookies from 'js-cookie';
import { useQuery } from '@tanstack/react-query';
import {
  COOKIES_USR,
  COOKIES_TOKEN_NAME,
  url,
  RouteConstants,
} from '../../helpers/constants';
import Skeleton from 'react-loading-skeleton';
import EditVerificationModal from '../../components/EditVerificationModal';
import Navbar from '../../components/Navbar';
import Sidenav from '../../components/Sidenav';
import { PiPencilSimple } from 'react-icons/pi';
import Loader from '../../components/Loader';
import { notification } from 'antd/es';
import { accounts_ms } from '../../helpers/api';
import { MdVerified } from 'react-icons/md';

function Verification() {
  const [verificationModal, openVerifcationModal] = useState<boolean>(false);
  const userId = cookies.get(`${COOKIES_USR}`);
  const token = cookies.get(`${COOKIES_TOKEN_NAME}`);
  const [submittingReview, setSubmittingReview] = useState(false);
  const [verification_details, setVerificationDetails] = useState({
    id: '',
    name: '',
    serial: '',
    media: {
      front_url: '',
      back_url: '',
    },
    verified: '',
    verified_at: '',
    country: {
      code: '',
      name: '',
    },
    issued_at: '',
    expires_at: '',
    under_review: false,
    valid: false,
  });

  const { isLoading, data, isSuccess, refetch } = useQuery({
    queryKey: ['verification-details'],
    queryFn: async () => {
      try {
        const user = await accounts_ms.get(`/v1/identification`);
        return user.data;
      } catch (error: any) {
        throw new Error(error?.response?.data?.message || error?.message);
      }
    },
    retry: 1,
    refetchOnWindowFocus(query) {
      return false;
    },
  });

  const expiresAt = new Date(
    verification_details?.expires_at
  ).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const issuedAt = new Date(verification_details?.issued_at).toLocaleDateString(
    'en-US',
    {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }
  );

  const handleSubmitForReview = async () => {
    setSubmittingReview(true);
    try {
      await accounts_ms.put(RouteConstants.SUBMIT_IDENTITY_FOR_REVIEW, {});
      notification.success({
        message: 'Identification details submitted successfully',
      });
      refetch();
    } catch (error: any) {
      notification.error({
        message: error?.response?.data?.message || error?.message,
      });
    } finally {
      setSubmittingReview(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setVerificationDetails(data?.payload);
    }

    // eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    setVerificationDetails(data?.payload);

    // eslint-disable-next-line
  }, [verificationModal]);

  if (userId && token) {
    return (
      <>
        <div>
          <Navbar title='Verification' /> <Sidenav />
          <section className='w-full md:w-[79%] lg:w-[88.3%] float-right  px-[2%]'>
            <div className='w-full mt-[110px] md:mt-[150px] mb-7 rounded-[30px] border-[1px] border-[#D4D3D3] p-5 '>
              {verification_details?.serial ? (
                <>
                  {' '}
                  <div className=' w-full flex flex-col flex-wrap mt-0 md:mt-0'>
                    <div className='flex mb-7 items-center justify-between'>
                      <div className='flex items-center gap-1'>
                        <p className='font-bold text-2xl text-[#484442]'>
                          National ID
                        </p>
                        {verification_details?.verified && (
                          <MdVerified className='text-[#B40303] w-5 h-5' />
                        )}
                      </div>
                      {!verification_details?.under_review &&
                        !verification_details?.valid && (
                          <button
                            className='flex flex-row items-center gap-1 font-semibold text-[#B40303]'
                            onClick={() => openVerifcationModal(true)}
                          >
                            <PiPencilSimple />{' '}
                            <p className='text-[12px]'>Edit profile</p>
                          </button>
                        )}
                    </div>
                    <div className=' w-[250px] sm:mr-10'>
                      <div className='capitalize text-[#939291]'>
                        ID Card Number
                      </div>
                      <div className='font-bold text-[#484442]'>
                        {!verification_details?.serial
                          ? '-'
                          : verification_details?.serial}{' '}
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 w-full grid grid-rows-2 md: gap-2 md:gap-0 md:grid-cols-2'>
                    <div className='mr-4'>
                      <div className='capitalize text-[#939291]'>
                        Issued Date
                      </div>
                      <div className='font-semibold text-[#484442]'>
                        {!verification_details?.issued_at ? '-' : issuedAt}
                      </div>
                    </div>
                    <div>
                      <div className='capitalize text-[#939291]'>
                        Expiry Date
                      </div>
                      <div className='font-semibold text-[#484442]'>
                        {!verification_details?.expires_at ? '-' : expiresAt}
                      </div>
                    </div>
                  </div>
                  <div className=' w-full md:flex pt-5 gap-3'>
                    <div className='w-full md:w-1/2'>
                      <div className='w-full'>
                        <div className='pb-3 text-[#939291]'>
                          Card Front Image
                        </div>
                        {verification_details?.media?.front_url ? (
                          <img
                            src={verification_details?.media.front_url}
                            alt='card front'
                            className='w-[561px]  h-[215px] object-cover rounded-lg ring-1 md:ring-2 ring-oya-gray-100 ring-offset-1 md:ring-offset-2 ring-offset-gray-50 appear'
                          />
                        ) : (
                          <img
                            src={url}
                            alt='card front'
                            className='w-[561px]  h-[215px] object-cover rounded-lg ring-1 md:ring-2 ring-oya-gray-100 ring-offset-1 md:ring-offset-2 ring-offset-gray-50 appear'
                          />
                        )}
                      </div>
                    </div>
                    <div className='w-full md:w-1/2 sm:mt-0 mt-5'>
                      <div className='w-full'>
                        <div className='pb-3 text-[#939291]'>
                          Card Back Image
                        </div>
                        {verification_details?.media?.back_url ? (
                          <img
                            src={verification_details.media.back_url}
                            alt='card front'
                            className='w-[561px]  h-[215px] object-cover rounded-lg ring-1 md:ring-2 ring-oya-gray-100 ring-offset-1 md:ring-offset-2 ring-offset-gray-50 appear'
                          />
                        ) : (
                          <img
                            src={url}
                            alt='card front'
                            className='w-[561px]  h-[215px] object-cover rounded-lg ring-1 md:ring-2 ring-oya-gray-100 ring-offset-1 md:ring-offset-2 ring-offset-gray-50 appear'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {!verification_details?.under_review && (
                    <>
                      {!verification_details?.valid && (
                        <div className='w-full mt-7'>
                          <button
                            type='button'
                            onClick={() => {
                              handleSubmitForReview();
                            }}
                            disabled={submittingReview}
                            className='flex rounded-md bg-red-101 text-white capitalize  px-7 py-3'
                          >
                            {submittingReview && <Loader />}
                            Submit For Review
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <Skeleton count={4} className='w-2/4' />
                  ) : (
                    <>
                      <p className='font-bold text-2xl text-[#484442] mb-7'>
                        National ID
                      </p>
                      <p className='text-[#484442] pb-5'>
                        You have not uploaded your National ID card
                      </p>

                      <button
                        className='flex flex-row bg-[#B40303] rounded-lg text-white py-3 px-7 gap-3 font-medium'
                        onClick={() => openVerifcationModal(true)}
                      >
                        <img
                          src={require('../../img/export.png')}
                          alt='Upload'
                        />
                        <p>Upload National ID</p>{' '}
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </section>
          <EditVerificationModal
            isOpen={verificationModal}
            handleClose={() => openVerifcationModal(false)}
            refetch={() => {
              refetch();
            }}
            verificationDetails={verification_details}
          />
        </div>
      </>
    );
  } else {
    return <Navigate to='/login' />;
  }
}

export default Verification;
