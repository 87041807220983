import { ChangeEvent, useEffect, useState } from 'react';
import MainLayout from '../../components/MainLayout';
import { useNavigate } from 'react-router-dom';
import { Select, Form, Input, notification, Tooltip, Avatar } from 'antd';
import { RouteConstants } from '../../helpers/constants';
import Loader from '../../components/Loader';
import PhoneInput, { Value } from 'react-phone-number-input';
import { useQuery } from '@tanstack/react-query';
import ProfilePhotoUpload from '../../components/ProfilePhotoUpload';
import axios from 'axios';
import { validateEmail } from '../../helpers/utils';
import { accounts_ms, ride_ms } from '../../helpers/api';

const EditProfile = () => {
  const [user_data, setUserData] = useState({
    image: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    birthdate: '',
    email: '',
    country: {
      name: '',
      code: '',
      phone_code: '',
    },
    gender: '',
    payment_account_number: '',
    payment_account_provider: '',
  });
  const [gender, setGender] = useState('');
  const [ice1_phone, setIce1Phone] = useState<Value | undefined>('');
  const [ice2_phone, setIce2Phone] = useState<Value | undefined>('');
  const [provider_phone, setProviderPhone] = useState<Value | undefined>('');
  const [openProfileUpload, setProfileUpload] = useState(false);
  const [signing, setSigning] = useState(false);
  const [network_providers, setNetworkProviders] = useState<any>([]);
  //const [signedImage, setSignedImage] = useState("");

  const { isLoading, refetch } = useQuery({
    retry: 1,
    queryKey: ['user-details'],
    queryFn: async () => {
      try {
        const user = await accounts_ms.get(RouteConstants.PROFILE);
        //console.log(user.data.data);
        setUserData(user.data?.payload);
        const genderValue = user.data?.payload?.gender === 'M' ? 'M' : 'F';
        //console.log(genderValue);
        setGender(genderValue);
        setIce1Phone(user.data?.payload?.ice1_phone);
        setIce2Phone(user.data?.payload?.ice2_phone);
        return user.data?.payload;
      } catch (e) {
        console.log(e);
      }
    },
  });

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const currentDate = new Date();
  const minAge = 12; // Minimum age required
  const maxAge = 100; // Maximum age allowed

  const handleDateOfBirthChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const selectedDate = new Date(event.target.value);
    if (isNaN(selectedDate.getTime())) {
      notification.warning({
        message: 'Invalid date. Please select a valid date of birth.',
      });
      return;
    }

    // Calculate age based on the selected date
    const age = currentDate.getFullYear() - selectedDate.getFullYear();

    // Check if the age is within the acceptable range
    if (age < minAge || age > maxAge) {
      notification.warning({
        message: 'Invalid age. Please select a valid date of birth.',
      });
    } else {
      //const formatted = Intl.DateTimeFormat("en-EN").format(selectedDate);
      const formatted = selectedDate.toISOString().split('T')[0];
      console.log(formatted);
      setUserData((prevData) => ({ ...prevData, birthdate: formatted }));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const emojiRegex =
      /[\u{1F300}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E6}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F1E6}-\u{1F1FF}]/u;

    // Check if the input value contains any special characters
    const containsSpecialCharacters = specialCharactersRegex.test(value);
    const emojiCharacters = emojiRegex.test(value);

    if (containsSpecialCharacters && emojiCharacters) {
      notification.error({
        message: 'First name cannot contain special character',
      });
    } else {
      setUserData((prevData) => ({ ...prevData, first_name: value }));
    }
  };

  const getProviders = async () => {
    try {
      const { data } = await ride_ms.get(RouteConstants.NETWORK_PROVIDERS);
      setNetworkProviders(
        data?.payload?.items?.map((item: any) => ({
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const uploadSignedImage = async (file: any) => {
    try {
      setSigning(true);
      const response = await accounts_ms.post(
        RouteConstants.PHOTO_SIGNED_UPLOAD,
        {
          mime: file?.type,
        }
      );
      console.log(response);
      axios
        .put(response.data?.payload?.upload_url, file, {
          headers: {
            'Content-Type': file?.type,
          },
        })
        .then(function () {
          setSigning(false);

          notification.success({
            message: 'Profile photo updated',
          });
          setProfileUpload(false);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      setSigning(false);
      console.log('Error signing profile :', error);
      setSigning(false);
    }
  };

  const handleSubmit = () => {
    let formatIce1Phone = ice1_phone?.replace(/\s+/g, '');
    let formatIce2Phone = ice2_phone?.replace(/\s+/g, '');
    let account_provider_number = provider_phone?.replace(/\s+/g, '');
    let emailValue = form.getFieldValue('email');
    setLoading(true);

    form
      .validateFields([
        'ice1_phone',
        'ice2_phone',
        'payment_account_provider',
        'payment_account_number',
      ])
      .then((val) => {
        console.log(val);
        accounts_ms
          .put(RouteConstants.PROFILE, {
            ...user_data,
            first_name: user_data.first_name,
            last_name: user_data.last_name,
            middle_name: user_data.middle_name,
            country: user_data.country.name,
            gender: gender,
            email: emailValue,
            ice1_phone: formatIce1Phone,
            ice2_phone: formatIce2Phone,
            birthdate: user_data.birthdate,
            payment_account_provider: val?.payment_account_provider,
            payment_account_number: account_provider_number,
          })
          .then((response) => {
            // console.log(user_data);
            notification.success({ message: 'Profile has been updated!' });
            //navigate(-1);
            refetch();
            setLoading(false);
          })
          .catch((e: any) => {
            notification.error({
              message: e?.response?.data?.message || e?.message,
            });
            console.log(e);
          })
          .finally(() => setLoading(false));
      })
      .catch((e) => {
        //handle errors
        setLoading(false);
      });
  };

  useEffect(() => {
    getProviders();
  }, []);

  useEffect(() => {
    form.setFieldsValue(user_data);
  }, [user_data, form]);
  //console.log(gender);

  return (
    <section className='max-w-6xl mx-auto h-full mb-8'>
      <MainLayout
        title='Edit Profile'
        subTitle='You can change personal details.'
      >
        <div>
          <ProfilePhotoUpload
            signing={signing}
            loading={loading}
            handleSubmit={(file) => {
              if (file) {
                uploadSignedImage(file);
              }
            }}
            isOpen={openProfileUpload}
            handleClose={() => setProfileUpload(false)}
          />
          <div className='flex items-center flex-wrap gap-3 sm:mt-[200px] mb-14 sm:mb-8'>
            {user_data?.image ? (
              <Avatar
                src={user_data?.image}
                alt='Profile'
                size={{
                  xl: 160,
                  xs: 100,
                  md: 160,
                  xxl: 160,
                  lg: 160,
                }}
              />
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className=' w-24 h-24 sm:w-32 sm:h-32 rounded-full text-oya-ghana-green'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z'
                />
              </svg>
            )}
            <Tooltip placement='top' title='Edit your profile picture'>
              <button
                className='bg-gray-200 sm:w-12 sm:h-12 w-10 h-10 p-3 bottom-[38%] right-[54%] flex rounded-full justify-center items-center'
                onClick={() => setProfileUpload(true)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke-width='1.5'
                  stroke='currentColor'
                  className='w-8 h-8 text-oya-ghana-green hover:text-red-700'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                  />
                </svg>
              </button>
            </Tooltip>
            <div className='capitalize text-oya-ghana-header-h5'>
              Profile info
            </div>
          </div>
          {isLoading ? (
            // <Skeleton count={6} />
            <>
              <div className='w-full lg:grid lg:grid-cols-2 gap-10 animate-pulse'>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-full rounded-[5px]'></div>
              </div>
              <div className='flex justify-end gap-10 animate-pulse mt-3'>
                <div className='bg-gray-300 h-10 w-[150px] rounded-[5px]'></div>
                <div className='bg-gray-300 h-10 w-[150px] rounded-[5px]'></div>
              </div>
            </>
          ) : (
            <div className='mt-8 w-full'>
              <Form
                form={form}
                layout='vertical'
                className='w-full'
                data-testid='edit-profile-submit-form'
                onFinish={(e) => {
                  //e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className='w-full flex items-center flex-wrap'>
                  <Form.Item
                    label={
                      <label
                        htmlFor='first_name'
                        className='block capitalize text-sm text-gray-601'
                      >
                        First name
                      </label>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      {
                        validator: (_, value) =>
                          !value?.includes(' ')
                            ? Promise.resolve()
                            : Promise.reject(new Error('No spaces allowed')),
                      },
                    ]}
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                  >
                    <Input
                      data-testid='edit-profile-first-name-input'
                      type='text'
                      minLength={2}
                      autoComplete='off'
                      value={user_data.first_name}
                      onChange={handleInputChange}
                      size='large'
                      name='first_name'
                      className='w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <label
                        htmlFor='middle_name'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Middle name
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                  >
                    <Input
                      data-testid='edit-profile-middle-name-input'
                      type='text'
                      autoComplete='off'
                      value={user_data.middle_name && user_data.middle_name}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          middle_name: e.target.value,
                        }))
                      }
                      size='large'
                      name='middle_name'
                      className='w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                    />
                  </Form.Item>
                </div>
                <div className='w-full flex items-center flex-wrap'>
                  <Form.Item
                    label={
                      <label
                        htmlFor='last_name'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Last name
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                  >
                    <Input
                      data-testid='edit-profile-last-name-input'
                      type='text'
                      minLength={2}
                      autoComplete='off'
                      value={user_data.last_name}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          last_name: e.target.value,
                        }))
                      }
                      size='large'
                      name='last_name'
                      className='w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <label
                        htmlFor='dob'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Date Of Birth
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                  >
                    <Input
                      data-testid='edit-profile-dob-input'
                      type='date'
                      value={user_data.birthdate}
                      autoComplete='off'
                      size='large'
                      onChange={handleDateOfBirthChange}
                      name='dob'
                      className='w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                    />
                  </Form.Item>
                </div>
                <div className='w-full flex'>
                  <Form.Item
                    colon={false}
                    label='Gender'
                    name='gender'
                    className='w-1/2 mb-2 pr-3'
                  >
                    <Select
                      size='large'
                      value={gender}
                      onChange={(e) => {
                        setGender(e);
                        form.setFieldsValue({ e });
                      }}
                      className='w-1/2 mb-2 pr-3'
                      data-testid='edit-profile-gender-select'
                      options={[
                        {
                          value: 'M',
                          label: 'Male',
                        },
                        {
                          value: 'F',
                          label: 'Female',
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    rules={[{ validator: validateEmail }]}
                    label={
                      <label
                        htmlFor='email'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Email
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                    name='email'
                  >
                    <Input
                      data-testid='edit-profile-email-input'
                      type='text'
                      minLength={2}
                      autoComplete='off'
                      value={user_data.email}
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          email: e.target.value,
                        }))
                      }
                      size='large'
                      className='w-full px-4 mb-4 border border-gray-200 rounded-md outline-none h-10'
                    />
                  </Form.Item>
                </div>
                <div className='w-full flex items-center flex-wrap my-4'>
                  <Form.Item
                    label={
                      <label
                        htmlFor='ice1_phone'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Primary Emergency
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <PhoneInput
                      id='phone1'
                      className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10'
                      data-testid='edit-profile-emergency-1-input'
                      defaultCountry='GH'
                      name='ice1_phone'
                      value={ice1_phone}
                      onChange={(e) => setIce1Phone(e)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <label
                        htmlFor='ice2_phone'
                        className='block capitalize text-sm text-gray-601'
                      >
                        Secondary Emergency
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <PhoneInput
                      id='phone2'
                      className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 '
                      data-testid='edit-profile-emergency-1-input'
                      defaultCountry='GH'
                      autoComplete='off'
                      name='ice2_phone'
                      value={ice2_phone}
                      onChange={(e) => setIce2Phone(e)}
                    />
                  </Form.Item>
                </div>

                <div className='w-full flex items-center flex-wrap my-4'>
                  <Form.Item
                    name={'payment_account_provider'}
                    label={
                      <label className='block capitalize text-sm text-gray-601'>
                        Payment network provider
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <Select
                      size='large'
                      value={user_data?.payment_account_provider}
                      className='w-1/2 mb-2 pr-3'
                      options={network_providers}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'payment_account_number'}
                    label={
                      <label className='block capitalize text-sm text-gray-601'>
                        Payment phone number
                      </label>
                    }
                    className='w-1/2 mb-2 pr-3'
                    colon={false}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <PhoneInput
                      id='providerphone'
                      className='phone w-full px-4 mt-2 mb-4 border border-gray-200 rounded-md outline-none h-10 '
                      defaultCountry='GH'
                      autoComplete='off'
                      value={provider_phone}
                      onChange={(e) => setProviderPhone(e)}
                    />
                  </Form.Item>
                </div>

                <div className='w-full flex justify-end items-end mt-6 mb-10'>
                  <div className='mr-2'>
                    <button
                      type='submit'
                      className='rounded-md flex bg-red-101 text-white capitalize p-3'
                      disabled={loading}
                    >
                      {loading && <Loader />}
                      update profile
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate('/account')}
                      className='rounded-md border font-bold capitalize p-3'
                      disabled={loading}
                      type='button'
                    >
                      cancel
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </div>
      </MainLayout>
    </section>
  );
};

export default EditProfile;
